import React from "react"

import Layout from "../components/layout"

import Img from "gatsby-image"
import {graphql} from "gatsby"
import {Col, Row} from "antd";

const MakeNerpaPage = ({data}) => {
    return (
        <Layout isNerpa={true}>
            <Row>
                <Col xs={24} sm={8}>
                    <Img fluid={data.card1.childImageSharp.fluid}/>
                </Col>
                <Col xs={24} sm={8}>
                    <Img fluid={data.card2.childImageSharp.fluid}/>
                </Col>
                <Col xs={24} sm={8}>
                    <Img fluid={data.card3.childImageSharp.fluid}/>
                </Col>
            </Row>

            <Row>
                <Col xs={24} sm={8}>
                    <Img fluid={data.card4.childImageSharp.fluid}/>
                </Col>
                <Col xs={24} sm={8}>
                    <Img fluid={data.card5.childImageSharp.fluid}/>
                </Col>
                <Col xs={24} sm={8}>
                    <Img fluid={data.card6.childImageSharp.fluid}/>
                </Col>
            </Row>

            <br />
            <br />
        </Layout>
    )
};

export default MakeNerpaPage

export const query = graphql`
    query {
        card1: file(relativePath: {eq: "cards/NERPA_cards_01.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        card2: file(relativePath: {eq: "cards/NERPA_cards_02.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        card3: file(relativePath: {eq: "cards/NERPA_cards_03.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        card4: file(relativePath: {eq: "cards/NERPA_cards_04.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        card5: file(relativePath: {eq: "cards/NERPA_cards_05.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        card6: file(relativePath: {eq: "cards/NERPA_cards_06.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;